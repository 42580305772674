export default {
  __name: 'dev',
  setup: function setup(__props) {
    var stage = process.env.LAMBDA_STAGE;
    var devStage = "dev";
    var _useNuxtApp = useNuxtApp(),
      $store = _useNuxtApp.$store;
    var account = computed(function () {
      return $store.state.user || {
        attributes: {},
        signInUserSession: {}
      };
    });
    var attributes = computed(function () {
      return account.value.attributes || {};
    });
    var userSession = computed(function () {
      return account.value.signInUserSession || {
        accessToken: {},
        idToken: {}
      };
    });
    var idToken = computed(function () {
      // idToken is what I want to be using
      var token = userSession.value.idToken || {
        payload: {}
      };
      return token;
    });
    var dev = computed(function () {
      return stage === devStage;
    });
    return {
      __sfc: true,
      stage: stage,
      devStage: devStage,
      $store: $store,
      account: account,
      attributes: attributes,
      userSession: userSession,
      idToken: idToken,
      dev: dev
    };
  }
};